import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class LessonService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/lesson';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }
    
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;

        }
        if (queryString)
            url = url + '&page=' + index;
        else
            url = url + '?page=' + index;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }
}
